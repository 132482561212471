export const MANAGEMENT_PERMISSIONS = [
    {
        feature: 'Roles',
        permissions: ['list-roles', 'manage-roles'],
    },
];

export const OPERATION_PERMISSIONS = [
    {
        feature: 'Projects',
        permissions: ['view-projects', 'list-projects', 'create-projects', 'update-projects', 'delete-projects', 'comment-projects', 'status-projects', 'finance-projects'],
    },
    {
        feature: 'Project Expenses',
        permissions: ['view-expenses', 'list-expenses', 'create-expenses', 'update-expenses', 'delete-expenses'],
    },
];

export const FINANCE_PERMISSIONS = [
    {
        feature: 'Project Invoices',
        permissions: ['view-invoices', 'list-invoices', 'create-invoices', 'update-invoices', 'delete-invoices'],
    },
];

export const GENERAL_PERMISSIONS = [
    {
        feature: 'Clients',
        permissions: ['view-clients', 'list-clients', 'create-clients', 'update-clients', 'delete-clients'],
    },
    {
        feature: 'Project Comments',
        permissions: ['view-comments', 'list-comments', 'create-comments', 'update-comments', 'delete-comments'],
    },
    {
        feature: 'Project Types',
        permissions: ['view-types', 'list-types', 'create-types', 'update-types', 'delete-types'],
    },
    {
        feature: 'Project Detail Types',
        permissions: ['view-detail-types', 'list-detail-types', 'create-detail-types', 'update-detail-types', 'delete-detail-types'],
    },
    {
        feature: 'Events',
        permissions: ['view-events', 'list-events', 'create-events', 'update-events', 'delete-events'],
    },
    {
        feature: 'Users',
        permissions: ['view-users', 'list-users', 'create-users', 'update-users', 'delete-users', 'search-users', 'refresh-users'],
    },
];

export default {
    MANAGEMENT_PERMISSIONS,
    OPERATION_PERMISSIONS,
    FINANCE_PERMISSIONS,
    GENERAL_PERMISSIONS,
};