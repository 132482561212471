import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import ErrorHandling from "../../../libraries/error-handling";
import {toast, ToastContainer} from "react-toastify";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _ from "lodash";
import { FINANCE_PERMISSIONS, GENERAL_PERMISSIONS, MANAGEMENT_PERMISSIONS, OPERATION_PERMISSIONS } from '../../../constants/permissions';

require('dotenv').config();
const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

class RolesEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            loadingButton: false,
            errors: {},

            roles: [],
            initialPermissions: [],
            permissions: [],
            permissionsAll: [],
            checkedPermission: [],

            id: '',
            displayName: '',
            name: '',
        };
        this.handleChange = this.handleChange.bind(this);   
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        document.title = 'Abadi - Edit Permission';

        Api.get('/roles?limit=0').then(resp => {
            if (resp.data) {
                this.setState({
                    roles: resp.data 
                }, () => {
                    this.getCurrent(id);
                    this.getCurrent(id, true);
                });
            }
        });

        Api.get('/permissions?limit=0').then(resp => {
            if (resp.data) {
                const mappedPermissions = this.mapPermissionsByGroup(resp.data);
                this.setState({ permissionsAll: mappedPermissions });
            }
        });
    }

    handleChange (e, prop){
        this.getCurrent(e.target.value);
        this.setState({ [prop]: e.target.value });
    }

    getCurrent(id, isInitialPermissions = false) {
        let list = [];

        if (this.state.roles.length > 0) {
            let found = this.state.roles.filter(function(no) {
                return no.id === id;
            });

            let data = found[0].permissions.data;

            if (data) {
                _.forEach(data,function(v){
                    list.push(v.id);
                });
            }

            if (isInitialPermissions) {
                this.setState({ initialPermissions: list });
            } else {
                this.setState({
                    displayName: found[0].display_name,
                    name: found[0].id,
                    permissions: list,
                });
            }
        } else {
            return '';
        }
    }

    getChecked(name) {
        if(this.state.permissions.length > 0) {
            let found = this.state.permissions.includes(name);
            return found;
        }else {
            return false
        }
    }

    showMessage = (status, message) => {
        if (status) {
            toast.success(message, { position: toast.POSITION.BOTTOM_RIGHT });
        } else {
            toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT });
        }
    }

    handleChecked = name => event => {
        let temp = this.state.permissions;
        let isChecked = event.target.checked;

        if (isChecked) {
            temp.push(name);
        } else {
            let index = temp.indexOf(name);
            if (index > -1) temp.splice(index, 1);
        }

        this.setState({ permissions: temp });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        if (!validator.allValid()) {
            this.setState({ errors: validator.getErrorMessages() });
            return false;
        }

        this.setState({ errors: {}, loadingButton: true });

        let detach_ids = (this.state.initialPermissions).filter((x) => !(this.state.permissions).includes(x));
        let params = {
            role_id : this.state.name,
            permissions_ids : this.state.permissions,
            ...(detach_ids.length > 0 ? { detach_ids } : {}),
        };

        Api.post('/permissions/attach', params, false).then(resp => {
            this.setState({ loadingButton: false });
            this.showMessage(true, 'Roles updated');
        }).catch(err => {
            if (ErrorHandling.checkErrorTokenExpired(err)){

            } else{
                this.setState({ errors: err.errors, loadingButton: false });
                this.showMessage(false, 'Invalid format data');
            }
        });
    }

    mapPermissionsByGroup = (permissions) => {
        let groupedResult = {
            management: [],
            operation: [],
            finance: [],
            general: [],
        };

        if (permissions && permissions?.length > 0) {
            permissions.forEach((permission) => {
                let isGrouped = false;
            
                MANAGEMENT_PERMISSIONS.forEach((group) => {
                    if (group.permissions.includes(permission.name)) {
                        let featureGroup = groupedResult.management.find((mgmt) => mgmt.feature === group.feature);
                        if (!featureGroup) {
                            featureGroup = { feature: group.feature, permissions: [] };
                            groupedResult.management.push(featureGroup);
                        }
            
                        featureGroup.permissions.push(permission);
                        isGrouped = true;
                    }
                });
            
                if (!isGrouped) {
                    OPERATION_PERMISSIONS.forEach((group) => {
                        if (group.permissions.includes(permission.name)) {
                            let featureGroup = groupedResult.operation.find((op) => op.feature === group.feature);
            
                            if (!featureGroup) {
                                featureGroup = { feature: group.feature, permissions: [] };
                                groupedResult.operation.push(featureGroup);
                            }
            
                            featureGroup.permissions.push(permission);
                            isGrouped = true;
                        }
                    });
                }

                if (!isGrouped) {
                    FINANCE_PERMISSIONS.forEach((group) => {
                        if (group.permissions.includes(permission.name)) {
                            let featureGroup = groupedResult.finance.find((finance) => finance.feature === group.feature);
            
                            if (!featureGroup) {
                                featureGroup = { feature: group.feature, permissions: [] };
                                groupedResult.finance.push(featureGroup);
                            }
            
                            featureGroup.permissions.push(permission);
                            isGrouped = true;
                        }
                    });
                }
            
                if (!isGrouped) {
                    GENERAL_PERMISSIONS.forEach((group) => {
                        if (group.permissions.includes(permission.name)) {
                            let featureGroup = groupedResult.general.find((general) => general.feature === group.feature);
            
                            if (!featureGroup) {
                                featureGroup = { feature: group.feature, permissions: [] };
                                groupedResult.general.push(featureGroup);
                            }
            
                            featureGroup.permissions.push(permission);
                            isGrouped = true;
                        }
                    });
                }
            });
        }

        return groupedResult;
    }


    render() {

        const permissionGroup = [
            {
                name: 'management',
                title: 'Management',
                icon: 'fas fa-user-tag',
                mainGroupPermissions: this.state.permissionsAll.management
            },
            {
                name: 'operation',
                title: 'Operation',
                icon: 'fas fa-paste',
                mainGroupPermissions: this.state.permissionsAll.operation
            },
            {
                name: 'finance',
                title: 'Finance',
                icon: 'fas fa-wallet',
                mainGroupPermissions: this.state.permissionsAll.finance
            },
            {
                name: 'general',
                title: 'General',
                icon: 'fas fa-cog',
                mainGroupPermissions: this.state.permissionsAll.general
            },
        ];

        return (
            <div className="row main-content permission-page">
                <div className="col-12">
                    <h2 className="page-title">Permission</h2>
                    {/* <h2 className="page-title">Permission {this.state.displayName ? `( ${this.state.displayName} )` : ``}</h2> */}
                </div>
                <div className="col-12 mt-3">
                    <div className="table-wrapper">
                        <form name="edit" id="editPermission" className="row" noValidate>
                            <div className="col-12">
                                <h2 className="page-title">{this.state.displayName}</h2>

                                {permissionGroup.map((group, key) => {
                                    return (
                                        <div key={key}>
                                            <div className="permission-group-title">
                                                {group?.icon ? <i className={group.icon}></i> : ''} {group?.title}
                                            </div>
                                            <div className="row permission-group-list">
                                                {group?.mainGroupPermissions?.map((item, key) => (
                                                    <div className='permission-feature-group col-6'>
                                                        <div className='permission-feature-label'>{item?.feature}</div>
                                                        <div className='permission-feature-list'>
                                                            {item?.permissions?.map((item, key) => (
                                                                <FormGroup key={key} className="">
                                                                    <FormControlLabel
                                                                        color="default"
                                                                        control={
                                                                            <Checkbox
                                                                            checked={this.getChecked(item.id)}
                                                                            onChange={this.handleChecked(item.id)}
                                                                            value={item.id}/>
                                                                        }
                                                                        label={item?.description}
                                                                        className="mb-0"
                                                                    />
                                                                </FormGroup>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="mt-5">
                                    <Link to="/roles" className="btn btn-blue-trans mr-3">
                                        Cancel
                                    </Link>
                                    <button
                                        className="btn btn-blue"
                                        onClick={this.handleSubmit}
                                        disabled={this.state.loadingButton && 'disabled' }
                                    >
                                        Save { this.state.loadingButton && <i className="fa fa-spinner fa-spin"> </i> }
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <ToastContainer autoClose={3000}/>
            </div>
        )
    }
}

export default RolesEdit;
